import React, { Component } from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import PropTypes from 'prop-types'
import './PlayerList.scss'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

class PlayerList extends Component {
  static get propTypes () {
    return {
      players: PropTypes.array,
      playerName: PropTypes.string,
      colorBlind: PropTypes.bool,
      xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    }
  }

  render () {
    const bluePlayers = this.props.players.filter(p => p.color === 'blue')
    const redPlayers = this.props.players.filter(p => p.color === 'red')
    const spectators = this.props.players.filter(p => p.color === 'spec')
    const rows = []
    for (let i = 0; i < Math.max(bluePlayers.length, redPlayers.length); i++) {
      rows.push([bluePlayers[i], redPlayers[i]])
    }

    const display = {
      xs: this.props.xs ? 'block' : 'none',
      sm: this.props.sm ? 'block' : 'none',
      md: this.props.md ? 'block' : 'none',
      lg: this.props.lg ? 'block' : 'none'
    }

    return (
      <Grid component={Box}
            container
            item
            justify="center"
            xs={this.props.xs}
            sm={this.props.sm}
            md={this.props.md}
            lg={this.props.lg}
            display={display}>
        <Grid container item justify="center" xs={12} className="player-list content-card">
          <Grid item xs={12}>
            <h2>Équipes</h2>
          </Grid>
          <Grid item xs={12} className="table">
            <TableContainer component={'span'}>
              <Table aria-label="players">
                <TableBody>
                  {
                    rows.map((row, i) =>
                      <TableRow key={i}>
                        <TableCell align="center"
                                   className={'blue cell' + (this.props.colorBlind ? ' cb' : '') + ' ' + ((row[0] && row[0].is_spy) ? 'spy' : '')}>
                          {row[0] ? row[0].name + (row[0].name === this.props.playerName ? ' (me)' : '') : ''}
                        </TableCell>
                        <TableCell align="center"
                                   className={'red cell' + (this.props.colorBlind ? ' cb' : '') + ' ' + ((row[1] && row[1].is_spy) ? 'spy' : '')}>
                          {row[1] ? row[1].name + (row[1].name === this.props.playerName ? ' (me)' : '') : ''}
                        </TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          {
            spectators.length > 0 &&
            <Grid item xs={12} className="spec-title">
              <h2>Spectateurs</h2>
            </Grid>
          }
          {
            spectators.length > 0 &&
            <Grid item xs={6} className="table">
              <TableContainer component={'span'}>
                <Table aria-label="spectators">
                  <TableBody>
                    {
                      spectators.map((spec, i) =>
                        <TableRow key={i}>
                          <TableCell align="center" className={'spec cell' + (this.props.colorBlind ? ' cb' : '')}>
                            {spec.name + (spec.name === this.props.playerName ? ' (me)' : '')}
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          }
        </Grid>
      </Grid>
    )
  }
}

export default PlayerList
