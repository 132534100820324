import React, { Component } from 'react'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import PropTypes from 'prop-types'
import './ClueList.scss'
import Grid from '@material-ui/core/Grid'
import { Box } from '@material-ui/core'

class ClueList extends Component {
  static get propTypes () {
    return {
      clues: PropTypes.array,
      colorBlind: PropTypes.bool,
      xs: PropTypes.number,
      sm: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number
    }
  }

  render () {
    const blueClues = this.props.clues.filter(c => c.color === 'blue')
    const redClues = this.props.clues.filter(c => c.color === 'red')
    const rows = []
    for (let i = 0; i < Math.max(blueClues.length, redClues.length); i++) {
      rows.push([blueClues[i], redClues[i]])
    }

    const display = {
      xs: this.props.xs ? 'block' : 'none',
      sm: this.props.sm ? 'block' : 'none',
      md: this.props.md ? 'block' : 'none',
      lg: this.props.lg ? 'block' : 'none'
    }

    return (
      <Grid component={Box}
            container
            item
            justify="center"
            xs={this.props.xs}
            sm={this.props.sm}
            md={this.props.md}
            lg={this.props.lg}
            display={display}>
        <Grid container item justify="center" xs={12} className="clue-list content-card">
          <Grid item xs={12}>
            <h2>Indices</h2>
          </Grid>
          <Grid item xs={12} className="table">
            <TableContainer component={'span'}>
              <Table aria-label="clues">
                <TableBody>
                  {
                    rows.map((row, i) =>
                      <TableRow key={i}>
                        <TableCell align="center" className={'cell word blue' + (this.props.colorBlind ? ' cb' : '')}>
                          {row[0] ? row[0].word : ''}
                        </TableCell>
                        <TableCell align="center"
                                   className={'cell number blue' + (this.props.colorBlind ? ' cb' : '')}>
                          {row[0] ? row[0].number === -1 ? 'inf' : row[0].number : ''}
                        </TableCell>

                        <TableCell align="center" className={'cell word red' + (this.props.colorBlind ? ' cb' : '')}>
                          {row[1] ? row[1].word : ''}
                        </TableCell>
                        <TableCell align="center" className={'cell number red' + (this.props.colorBlind ? ' cb' : '')}>
                          {row[1] ? row[1].number === -1 ? 'inf' : row[1].number : ''}
                        </TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default ClueList
