import React, { Component } from 'react'
import './GameList.scss'
import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import ReactRouterPropTypes from 'react-router-prop-types'

class GameList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      games: []
    }
  }

  static get propTypes () {
    return {
      history: ReactRouterPropTypes.history.isRequired
    }
  }

  componentDidMount () {
    fetch('api/publicGames?format=json', {
      method: 'GET'
    }).then(response => {
      return response.json()
    }).then(data => {
      this.setState({
        games: data
      })
    })
  }

  render () {
    return (
        <Grid container item xs={12} sm={10} md={7} justify="center" className="content-card game-list">
          <Grid item xs={12}>
            <h2>
              Parties ouvertes :
            </h2>
          </Grid>
          <Grid item xs={12} sm={11} md={8} lg={6}>
            <TableContainer component={'span'}>
              <Table aria-label="games">
                <TableBody>
                  {
                    this.state.games.map((game, i) =>
                        <TableRow key={i}>
                          <TableCell align="right" className="handwriting game-name">
                            {game.game_id}
                          </TableCell>
                          <TableCell align="center" className="players-amount">
                            {game.players_amount === -1 ? 0 : game.players_amount} joueur{game.players_amount !== 1 ? 's' : ''}
                          </TableCell>
                          <TableCell align="center" className="join-game">
                            <Button onClick={() => this.props.history.push('/game?game_id=' + game.game_id)} variant="contained" color="secondary">
                              Rejoindre
                            </Button>
                          </TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
    )
  }
}

export default GameList
