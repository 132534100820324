import React, { Component } from 'react'
import './Game.scss'
import Board from './board/Board'
import ReconnectingWebSocket from 'reconnecting-websocket'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import PlayerRegistration from './registration/PlayerRegistration'
import BottomRow from './board/BottomRow'
import { GameStateEnum, RequestTypeEnum } from '../../utils/enums'
import PlayerList from './common/PlayerList'
import Box from '@material-ui/core/Box'
import GameSettings from './common/GameSettings'

class Game extends Component {
  constructor (props) {
    super(props)

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const gameId = urlParams.get('game_id')
    const isSpy = false

    this.gameExists = this.gameExists.bind(this)
    this.editColorBlind = this.editColorBlind.bind(this)
    this.editAlwaysDisplayWords = this.editAlwaysDisplayWords.bind(this)
    this.playNotification = this.playNotification.bind(this)
    this.playEndSound = this.playEndSound.bind(this)
    this.onMessage = this.onMessage.bind(this)
    this.revealCard = this.revealCard.bind(this)
    this.setPlayers = this.setPlayers.bind(this)
    this.removePlayer = this.removePlayer.bind(this)
    this.addPlayer = this.addPlayer.bind(this)
    this.sendPlayer = this.sendPlayer.bind(this)
    this.sendReveal = this.sendReveal.bind(this)
    this.sendReset = this.sendReset.bind(this)
    this.sendClue = this.sendClue.bind(this)
    this.getCards = this.getCards.bind(this)
    this.getClues = this.getClues.bind(this)
    this.toggleSpy = this.toggleSpy.bind(this)
    this.endTurn = this.endTurn.bind(this)
    this.playersTurn = this.playersTurn.bind(this)
    this.drawContent = this.drawContent.bind(this)

    const wsScheme = window.location.protocol === 'https:' ? 'wss' : 'ws'
    const webSocket = new ReconnectingWebSocket(wsScheme + '://' + window.location.host + '/ws/game/' + gameId + '/')

    webSocket.onmessage = this.onMessage

    this.state = {
      cards: [],
      clues: [],
      players: [],
      playerName: '',
      playerColor: '',
      registerTry: 0,
      registered: false,
      spectator: false,
      loaded: false,
      gameId: gameId,
      isSpy: isSpy,
      webSocket: webSocket,
      game: null,
      clueError: false,
      resetting: false,
      colorBlind: false,
      alwaysDisplayWords: true,
      gameExists: -1,
      nav: ''
    }
  }

  componentDidMount () {
    this.gameExists()
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.GET_PLAYERS,
      game_id: this.state.gameId
    }))
  }

  gameExists () {
    fetch('api/gameExists?format=json&game_id=' + this.state.gameId, {
      method: 'GET'
    }).then(response => {
      return response.json()
    }).then(data => {
      this.setState({
        gameExists: data.length > 0 ? 1 : 0
      })
    })
  }

  editColorBlind (value) {
    this.setState({ colorBlind: value })
  }

  editAlwaysDisplayWords (value) {
    this.setState({ alwaysDisplayWords: value })
  }

  playNotification (playerAction) {
    const notification = playerAction
      ? document.getElementById('notification')
      : document.getElementById('notification-low')
    notification.play()
  }

  playEndSound (win) {
    const sound = win
      ? document.getElementById('victory')
      : document.getElementById('defeat')
    sound.play()
  }

  onMessage (message) {
    const data = JSON.parse(message.data)
    if (data.game) {
      this.setState({ game: data.game })
    }

    switch (data.type) {
      case RequestTypeEnum.REVEAL:
        if (data.game.state === GameStateEnum.FINISHED) {
          this.playEndSound(
            (data.game.blue_won && this.state.playerColor === 'blue') ||
            (!data.game.blue_won && this.state.playerColor === 'red')
          )
        } else {
          this.playNotification(this.playersTurn(data.game))
        }
        this.revealCard(data)
        break
      case RequestTypeEnum.RESET:
        if (data.error !== 1) {
          if (data.resetting) {
            this.setState({ resetting: true })
          } else {
            this.playNotification(this.playersTurn(data.game))
            this.setState({
              cards: data.cards,
              clues: [],
              isSpy: false,
              players: this.state.players.map(p => {
                p.is_spy = false
                return p
              }),
              resetting: false
            })
          }
        }
        break
      case RequestTypeEnum.REGISTER_PLAYER:
        this.playNotification(false)
        this.addPlayer(data)
        break
      case RequestTypeEnum.GET_PLAYERS:
        this.setPlayers(data)
        break
      case RequestTypeEnum.REMOVE_PLAYER:
        this.playNotification(false)
        this.removePlayer(data)
        break
      case RequestTypeEnum.GET_CARDS:
        if (data.error !== 1) {
          this.setState({ cards: data.cards, loaded: true })
        }
        break
      case RequestTypeEnum.TOGGLE_SPY:
        if (data.error !== 1) {
          this.setState({
            players: this.state.players.map(p => {
              if (p.name === data.name) { p.is_spy = data.is_spy }
              return p
            })
          })
        }
        break
      case RequestTypeEnum.GET_CLUES:
        if (data.error !== 1) {
          this.setState({ clues: data.clues })
        }
        break
      case RequestTypeEnum.ADD_CLUE:
        this.playNotification(this.playersTurn(data.game))
        this.addClue(data)
        break
      case RequestTypeEnum.END_TURN:
        this.playNotification(this.playersTurn(data.game))
        break
      default:
        break
    }
  }

  revealCard (data) {
    if (data.error !== 1) {
      const newCards = this.state.cards
      newCards.map(c => {
        if (c.word === data.word) {
          c.revealed = true
        }
        return c
      })

      this.setState({
        cards: newCards
      })
    }
  }

  setPlayers (data) {
    if (data.error !== 1) {
      this.setState({
        players: data.players
      })
    }
  }

  removePlayer (data) {
    const players = this.state.players.filter(p => p.name !== data.name)
    this.setState({
      players: players
    })
  }

  addClue (data) {
    if (data.error !== 1) {
      const clues = this.state.clues
      clues.push({
        game_id: this.state.gameId,
        word: data.word,
        number: data.number,
        color: data.color
      })

      this.setState({ clues: clues })
    } else {
      this.setState({ clueError: true })
    }
  }

  addPlayer (data) {
    if (data.error === 1) {
      this.setState({
        playerName: '',
        playerColor: '',
        registerTry: this.state.registerTry + 1
      })
      return
    }

    if (data.self) {
      this.setState({
        playerName: data.player.name,
        playerColor: data.player.color,
        spectator: data.player.color === 'spec',
        registered: true
      })
      this.getCards()
      this.getClues()
    }

    const players = this.state.players
    players.push(data.player)
    this.setState({ players: players })
  }

  getCards () {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.GET_CARDS,
      game_id: this.state.gameId
    }))
  }

  getClues () {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.GET_CLUES,
      game_id: this.state.gameId
    }))
  }

  sendPlayer (playerName, color) {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.REGISTER_PLAYER,
      game_id: this.state.gameId,
      name: playerName,
      color: color
    }))
  }

  sendReveal (word) {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.REVEAL,
      game_id: this.state.gameId,
      word: word
    }))
  }

  sendReset () {
    if (!window.confirm('Recommencer une nouvelle partie ?')) {
      return
    }

    this.setState({ resetting: true })
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.RESET,
      game_id: this.state.gameId
    }))
  }

  sendClue (word, number) {
    this.setState({ clueError: false })
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.ADD_CLUE,
      game_id: this.state.gameId,
      word: word,
      number: number,
      color: this.state.playerColor
    }))
  }

  toggleSpy () {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.TOGGLE_SPY,
      is_spy: !this.state.isSpy
    }))
    this.setState({ isSpy: !this.state.isSpy })
  }

  endTurn () {
    this.state.webSocket.send(JSON.stringify({
      type: RequestTypeEnum.END_TURN
    }))
  }

  playersTurn (referenceGame) {
    const game = referenceGame || this.state.game
    if (!game) {
      return false
    }
    switch (game.state) {
      case GameStateEnum.BLUE_SPY_TURN:
        return this.state.isSpy && this.state.playerColor === 'blue'
      case GameStateEnum.RED_SPY_TURN:
        return this.state.isSpy && this.state.playerColor === 'red'
      case GameStateEnum.BLUE_TURN:
        return !this.state.isSpy && this.state.playerColor === 'blue'
      case GameStateEnum.RED_TURN:
        return !this.state.isSpy && this.state.playerColor === 'red'
      default:
        return false
    }
  }

  drawContent () {
    if (this.state.gameExists === -1) {
      return (
        <Grid container item justify="center" xs={12} sm={10} md={7} className="content-card">
          <Grid item xs={12}>
            <h2>
              { 'Chargement...' }
            </h2>
          </Grid>
        </Grid>
      )
    } else if (this.state.gameExists === 0) {
      window.location.href = '/'
      return (
        <div/>
      )
    } else if (!this.state.registered) {
      return (
        <Grid container item xs={12} direction="column" justify="flex-start">
          <Grid container item justify="center">
            <PlayerRegistration gameId={this.state.gameId}
                                try={this.state.registerTry}
                                players={this.state.players}
                                sendPlayer={this.sendPlayer} />
          </Grid>
          <Grid container item justify="center">
            <PlayerList xs={12} sm={7} md={5} lg={3}
                        players={this.state.players}
                        playerName={this.state.playerName}
                        colorBlind={this.state.colorBlind} />
          </Grid>
        </Grid>
      )
    } else if (this.state.resetting) {
      return (
        <Grid container item justify="center" xs={12} sm={10} md={7} className="content-card">
          <Grid item xs={12}>
            <h2>
              { "Création d'une nouvelle partie..." }
            </h2>
          </Grid>
        </Grid>
      )
    } else if (!this.state.loaded) {
      return (
        <Grid container item justify="center" xs={12} sm={10} md={7} className="content-card">
          <Grid item xs={12}>
            <h2>
              { 'Chargement...' }
            </h2>
          </Grid>
        </Grid>
      )
    } else {
      const buttonColor = this.state.playerColor === 'spec'
        ? 'default'
        : this.state.playerColor === 'blue'
          ? 'primary'
          : 'secondary'
      const buttonClass = this.state.playerColor === 'spec'
        ? 'neutral-button'
        : ''
      const desktopDisplay = {
        xs: 'none',
        md: 'block'
      }
      return (
        <Grid container item xs={12} direction="column" justify="flex-start">
          <Grid container item justify="center">
            <GameSettings card
                          buttonColor={buttonColor}
                          colorBlind={this.state.colorBlind}
                          editColorBlind={this.editColorBlind}
                          alwaysDisplayWords={this.state.alwaysDisplayWords}
                          editAlwaysDisplayWords={this.editAlwaysDisplayWords}
            />
          </Grid>
          <Grid container item justify="center">
           <Board gameId={this.state.gameId}
                  showColor={this.state.game.state === GameStateEnum.FINISHED}
                  game={this.state.game}
                  cards={this.state.cards}
                  isSpy={this.state.isSpy}
                  playersTurn={this.playersTurn()}
                  sendReveal={this.sendReveal}
                  colorBlind={this.state.colorBlind}
                  alwaysDisplayWords={this.state.alwaysDisplayWords}
           />
          </Grid>
          <Grid container item justify="center" style={{ marginTop: '2rem' }}>
            <Grid component={Box}
                  display={desktopDisplay}
                  container
                  item
                  justify="center"
                  xs={12}
                  style={{ marginTop: '2rem' }}>
              <Grid container item justify="center" xs={12}>
                <Grid item xs={12} sm={6} md={4}>
                  <Button variant="contained" color={buttonColor} className={'game-button ' + buttonClass} onClick={this.toggleSpy}>
                    {
                      !this.state.spectator
                        ? 'Passer ' + (this.state.isSpy ? 'joueur' : 'espion')
                        : (this.state.isSpy ? 'Cacher' : 'Révéler') + ' la carte'
                    }
                  </Button>
                </Grid>
                {
                  !this.state.spectator &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Button variant="contained" color={buttonColor} className="game-button" onClick={this.sendReset}>Recommencer une partie</Button>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid container item justify="center" className="sticky">
            <BottomRow game={this.state.game}
                       players={this.state.players}
                       clues={this.state.clues}
                       sendClue={this.sendClue}
                       sendReset={this.sendReset}
                       toggleSpy={this.toggleSpy}
                       spectator={this.state.spectator}
                       registered={this.state.registered}
                       isSpy={this.state.isSpy}
                       playerName={this.state.playerName}
                       playersTurn={this.playersTurn()}
                       endTurn={this.endTurn}
                       clueError={this.state.clueError}
                       resetting={this.state.resetting}
                       buttonColor={buttonColor}
                       editColorBlind={this.editColorBlind}
                       colorBlind={this.state.colorBlind}
                       editAlwaysDisplayWords={this.editAlwaysDisplayWords}
                       alwaysDisplayWords={this.state.alwaysDisplayWords}
            />
          </Grid>
        </Grid>
      )
    }
  }

  render () {
    return (
      <Grid container item xs={12} justify="center" className="game">
        <audio id="notification">
          <source src="/static/media/sound/notification.mp3"/>
        </audio>
        <audio id="notification-low">
          <source src="/static/media/sound/notification-low.mp3"/>
        </audio>
        <audio id="victory">
          <source src="/static/media/sound/victory.mp3"/>
        </audio>
        <audio id="defeat">
          <source src="/static/media/sound/defeat.mp3"/>
        </audio>
        {
          this.drawContent()
        }
      </Grid>
    )
  }
}

export default Game
