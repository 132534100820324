import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button, Input } from '@material-ui/core'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import './PlayerRegistration.scss'
import Checkbox from '@material-ui/core/Checkbox'
import PropTypes from 'prop-types'

class PlayerRegistration extends Component {
  constructor (props) {
    super(props)

    this.state = {
      registering: false,
      name: '',
      color: '',
      err: false,
      sendPlayer: props.sendPlayer,
      spectator: false
    }

    this.register = this.register.bind(this)
  }

  static get propTypes () {
    return {
      sendPlayer: PropTypes.func,
      try: PropTypes.number,
      gameId: PropTypes.string
    }
  }

  register () {
    this.setState({ registering: true, err: false })
    if (this.checkPlayer(this.state.name, this.state.spectator, this.state.color)) {
      this.state.sendPlayer(this.state.name, this.state.spectator ? 'spec' : this.state.color)
    } else {
      this.setState({ registering: false, err: true })
    }
  }

  checkPlayer (name, spectator, color) {
    return (spectator || (color && (color === 'blue' || color === 'red'))) && name && name.length > 0 && name.length <= 16
  }

  componentDidUpdate (prevProps) {
    if (this.props.try !== prevProps.try) {
      this.setState({ registering: false, err: true })
    }
  }

  render () {
    if (this.state.registering) {
      return (
        <Grid container item justify="center" xs={12} sm={10} md={7} className="player-registration">
          <Grid item xs={12}>
            <span>Enregistrement...</span>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container item justify="center" xs={12} sm={10} md={7} className="player-registration content-card">
        <Grid item xs={12}>
          <h1>Code Names</h1>
        </Grid>
        <Grid item xs={12}>
          <h2>Bienvenue sur la partie { this.props.gameId }</h2>
        </Grid>
        <Grid item xs={12}>
          {
            this.state.err &&
            <span style={{ color: 'red' }}>
              { "Erreur : ce joueur existe déja ou les informations rentrées sont invalides. Ton nom doit avoir entre 1 et 16 caractères, et n'oublie pas de choisir une couleur si tu n'es pas spectateur !" }
            </span>
          }
        </Grid>
        <Grid item xs={12} style={{ marginTop: '25px' }}>
          <h2>Quel est ton nom ?</h2>
        </Grid>
        <Grid item xs={11} sm={10} md={8} lg={6}>
          <Input fullWidth onChange={(event) => this.setState({ name: event.target.value })}/>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '25px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.spectator}
                onChange={event => this.setState({ spectator: event.target.checked })}
                name="spectator"
                color="default"
              />
            }
            label="Mode spectateur"
          />
        </Grid>
        {
          !this.state.spectator &&
          <Grid item xs={12} style={{ marginTop: '25px' }}>
            <h2>Quelle équipe veux-tu rejoindre ?</h2>
          </Grid>
        }
        {
          !this.state.spectator &&
          <Grid item>
            <RadioGroup aria-label="color"
                        name="color1"
                        onChange={(event) => this.setState({ color: event.target.value })}>
              <FormControlLabel value="blue" control={<Radio color="primary"/>} label="Bleu"/>
              <FormControlLabel value="red" control={<Radio color="secondary"/>} label="Rouge"/>
            </RadioGroup>
          </Grid>
        }
        <Grid item xs={12} style={{ marginTop: '25px' }}>
          <Button onClick={this.register} variant="contained" color="default" className="black-button" disabled={!this.state.name || !this.checkPlayer(this.state.name, this.state.spectator, this.state.color)}>
            { "M'enregistrer" }
          </Button>
        </Grid>
      </Grid>
    )
  }
}

export default PlayerRegistration
