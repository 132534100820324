import React, { Component } from 'react'
import './Index.scss'
import Grid from '@material-ui/core/Grid'
import CreateGameForm from './CreateGameForm'
import GameList from './GameList'
import ReactRouterPropTypes from 'react-router-prop-types'

class Index extends Component {
  constructor (props) {
    super(props)

    this.state = {
      err: false,
      created: false,
      id: ''
    }

    this.createGame = this.createGame.bind(this)
  }

  static get propTypes () {
    return {
      history: ReactRouterPropTypes.history.isRequired
    }
  }

  createGame (id) {
    fetch('api/createGame?', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        game_id: id
      })
    }).then(response => {
      if (response.status >= 400) {
        this.setState({
          err: true,
          created: false,
          id: ''
        })
      } else {
        this.setState({
          created: true,
          id: id
        })
      }
    })
  }

  render () {
    return (
      <Grid container item justify="center" xs={12}>
        <CreateGameForm history={this.props.history} />
        <GameList history={this.props.history} />
      </Grid>
    )
  }
}

export default Index
