import React, { Component } from 'react'
import './CreateGameForm.scss'
import { Input, Button, FormControl, InputLabel, Select, MenuItem, TextareaAutosize, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ReactRouterPropTypes from 'react-router-prop-types'

class CreateGameForm extends Component {
  constructor (props) {
    super(props)

    this.state = {
      invalid: false,
      creating: false,
      created: false,
      existed: false,
      themes: [{ theme: 'default', word_count: '-1' }],
      id: '',
      private: true,
      theme: 'default',
      secTheme: '',
      combine: false,
      customWords: []
    }

    this.validName = this.validName.bind(this)
    this.validForm = this.validForm.bind(this)
    this.createGame = this.createGame.bind(this)
    this.created = this.created.bind(this)
    this.changePrivate = this.changePrivate.bind(this)
    this.changeCombine = this.changeCombine.bind(this)
    this.insufficientWords = this.insufficientWords.bind(this)
    this.checkCombine = this.checkCombine.bind(this)
    this.editCustomWords = this.editCustomWords.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.drawThemeSelector = this.drawThemeSelector.bind(this)
    this.drawThemeSelection = this.drawThemeSelection.bind(this)
  }

  static get propTypes () {
    return {
      history: ReactRouterPropTypes.history.isRequired
    }
  }

  componentDidMount () {
    fetch('api/themes?format=json', {
      method: 'GET'
    }).then(response => {
      return response.json()
    }).then(data => {
      this.setState({
        themes: data.sort((a, b) => {
          if (a.theme === 'default') {
            return -1
          } else if (b.theme === 'default') {
            return 1
          } else {
            return a.theme === b.theme ? 0 : a.theme < b.theme ? -1 : 1
          }
        })
      })
    })
  }

  validForm () {
    return this.validName() && this.state.theme && (!this.insufficientWords() || this.state.secTheme !== '')
  }

  validName () {
    const name = this.state.id
    return name.length > 0 && name.length <= 32 && name.match(/^[a-zA-Z0-9]+$/) !== null
  }

  createGame () {
    const id = this.state.id
    this.setState({ invalid: false })
    if (!this.validName()) {
      this.setState({ invalid: true, creating: false })
      return
    }

    fetch('api/createGame?', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        game_id: id,
        private: this.state.private,
        theme: this.state.theme,
        custom: this.state.customWords,
        combine: this.checkCombine(),
        sec_theme: this.state.secTheme
      })
    }).then(response => {
      if (response.status >= 400) {
        this.setState({ // Invalid name
          invalid: true,
          creating: false,
          created: false,
          id: ''
        })
      } else {
        this.setState({
          existed: response.status === 200,
          creating: false,
          created: true,
          id: id
        })
      }
    })
  }

  created () {
    return this.state.created && this.state.id !== ''
  }

  changePrivate (event) {
    this.setState({ private: event.target.checked })
  }

  changeCombine (event) {
    this.setState({ combine: event.target.checked })
  }

  editCustomWords (event) {
    const words = event.target.value.toUpperCase().match(/[\w-]+/g)
    this.setState({ customWords: [...new Set(words)] })
  }

  insufficientWords () {
    return this.state.theme === 'custom' && this.state.customWords.length < 25
  }

  checkCombine () {
    return this.state.combine || this.insufficientWords()
  }

  submitForm (event) {
    this.setState({ creating: true })
    this.createGame()

    // Prevent form redirection
    event.preventDefault()
  }

  drawThemeSelector (mainTheme, selected = '') {
    return (
        <FormControl className="theme-select">
          <InputLabel id="demo-simple-select-label">Thème</InputLabel>
          <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={mainTheme ? this.state.theme : this.state.secTheme}
              onChange={(event) =>
                mainTheme ? this.setState({ theme: event.target.value }) : this.setState({ secTheme: event.target.value })
              }
          >
            {
              this.state.themes.filter(theme => theme.theme !== selected).map(theme =>
                  <MenuItem key={theme.theme} value={theme.theme}>
                    { (theme.theme === 'default' ? 'Par défaut' : theme.theme) + ' - ' + theme.word_count + ' mots' }
                  </MenuItem>
              )
            }
            {
              mainTheme &&
              <MenuItem value="custom">
                Mots personnalisés
              </MenuItem>
            }
          </Select>
        </FormControl>
    )
  }

  drawThemeSelection () {
    return (
        <Grid container item justify="center" xs={12} style={{ marginTop: '5px' }}>
          <Grid item xs={11} sm={10} md={8} lg={6}>
            {
              this.drawThemeSelector(true)
            }
          </Grid>
          {
            this.state.theme === 'custom' &&
            <Grid item xs={11} sm={10} md={8}>
                <TextareaAutosize aria-label="custom words"
                                  className="word-input"
                                  placeholder="Mots séparés par des virgules et/ou retours à la ligne. Mots composés autorisés uniquement avec tiret"
                                  onChange={this.editCustomWords} />
            </Grid>
          }
          <Grid item xs={11} sm={10} md={8}>
            <Tooltip title={
              this.insufficientWords()
                ? 'Obligatoire si le thème personnalisé comprend moins de 25 mots'
                : 'Utiliser des mots de deux thèmes pour créer le plateau'}>
              <FormControlLabel control={
                <Checkbox checked={this.checkCombine()}
                          onChange={this.changeCombine}
                          name="private"
                          color="primary" />
              } label="Combiner à un autre thème" />
            </Tooltip>
          </Grid>
          {
            this.checkCombine() &&
            <Grid item xs={11} sm={10} md={8} lg={6}>
              {
                this.drawThemeSelector(false, this.state.theme)
              }
            </Grid>
          }
        </Grid>
    )
  }

  render () {
    if (this.state.creating) {
      return (
        <Grid container item justify="center" xs={12} sm={10} md={7} className="create-game-form content-card">
          <Grid item xs={12}>
            <h2>Création...</h2>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container item justify="center" xs={12} sm={10} md={7} className="create-game-form content-card">
        <form onSubmit={this.submitForm}>
          <Grid item xs={12}>
            <h1>
              Code Names
            </h1>
          </Grid>
          <Grid item xs={12}>
            {
              !this.created() &&
              <h2>Créer / rejoindre une partie :</h2>
            }
          </Grid>
          <Grid container item xs={12} justify="center" style={{ marginTop: '5px' }}>
            <Grid item xs={11} sm={10} md={8} lg={6}>
              {
                this.created() &&
                !this.state.existed &&
                <h2>La partie {this.state.id} a été créée !</h2>
              }
              {
                this.created() &&
                this.state.existed &&
                <h2>La partie {this.state.id} existe déjà !</h2>
              }
              {
                !this.created() &&
                <Input fullWidth
                       onChange={(event) => this.setState({ id: event.target.value })}
                       color="primary" />
              }
            </Grid>
          </Grid>
          {
            !this.created() &&
            <Grid item xs={12} style={{ marginTop: '5px' }}>
              <FormControlLabel control={
                <Checkbox checked={this.state.private} onChange={this.changePrivate} name="private" color="secondary" />
              } label="Partie privée"/>
            </Grid>
          }
          {
            !this.created() &&
            this.drawThemeSelection()
          }
          <Grid item xs={12} style={{ marginTop: '10px' }}>
            {
              !this.created() &&
              <Button type="submit" variant="contained" color="primary" disabled={!this.validForm()}>
                Créer une partie
              </Button>
            }
            {
              this.created() &&
              <Button onClick={() => this.props.history.push('/game?game_id=' + this.state.id)}
                      variant="contained"
                      color="primary">
                Rejoindre la partie
              </Button>
            }
          </Grid>
          <Grid item xs={12}>
            {
              this.state.invalid &&
              <span style={{ color: 'red' }}>Erreur : le nom de la partie doit être un seul mot avec uniquement des chiffres et des lettres</span>
            }
          </Grid>
        </form>
      </Grid>
    )
  }
}

export default CreateGameForm
