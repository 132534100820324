import React from 'react'
import './Card.scss'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

class Card extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      revealed: props.revealed,
      showWord: !props.revealed,
      sendReveal: props.sendReveal
    }

    this.mouseEnter = this.mouseEnter.bind(this)
    this.mouseLeave = this.mouseLeave.bind(this)
    this.click = this.click.bind(this)
  }

  static get propTypes () {
    return {
      sendReveal: PropTypes.func,
      word: PropTypes.string,
      color: PropTypes.string,
      revealed: PropTypes.bool,
      isSpy: PropTypes.bool,
      playersTurn: PropTypes.bool,
      colorBlind: PropTypes.bool,
      alwaysDisplayWords: PropTypes.bool,
      showColor: PropTypes.bool
    }
  }

  mouseEnter () {
    if (this.state.revealed) {
      this.setState({ showWord: true })
    }
  }

  mouseLeave () {
    if (this.state.revealed) {
      this.setState({ showWord: this.props.alwaysDisplayWords })
    }
  }

  click () {
    if (!this.state.revealed) {
      this.state.sendReveal(this.props.word)
    }
  }

  negDisplay () {
    return this.state.revealed && this.props.alwaysDisplayWords
  }

  componentDidUpdate (prevProps) {
    if (this.props.revealed !== prevProps.revealed) {
      this.setState({
        revealed: this.props.revealed,
        showWord: !this.props.revealed || this.props.alwaysDisplayWords
      })
    }
    if (this.state.revealed && this.props.alwaysDisplayWords !== prevProps.alwaysDisplayWords) {
      this.setState({ showWord: this.props.alwaysDisplayWords })
    }
  }

  render () {
    const divClass =
      'content-card ' +
      (this.state.showWord
        ? (this.negDisplay() ? (this.props.color + ' ') : '')
        : (this.props.color + (this.props.colorBlind ? ' cb' : ''))) + ' ' +
      (!this.props.isSpy && this.props.playersTurn && !this.state.revealed ? 'selectable' : '')
    const wordClass =
      (this.state.showWord ? '' : 'invisible ') + 'word ' +
      (this.negDisplay()
        ? 'white-text'
        : (this.props.isSpy || this.props.showColor ? (this.props.color + ' text' + (this.props.colorBlind ? ' cb' : '')) : ''))

    return (
      <Grid item xs={11} sm={6} md={3} lg={2} className="card">
        <Grid item xs={12}
              className={divClass}
              onMouseEnter={this.mouseEnter}
              onMouseLeave={this.mouseLeave}
              onClick={this.props.isSpy || !this.props.playersTurn
                ? () => {}
                : this.click}>
          <span className={wordClass}>
            {this.props.word}
          </span>
        </Grid>
      </Grid>
    )
  }
}

export default Card
