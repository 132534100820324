import React, { Component } from 'react'
import './ClueSender.scss'
import { Input, Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import PropTypes from 'prop-types'

class ClueSender extends Component {
  constructor (props) {
    super(props)

    this.state = {
      word: '',
      number: 0,
      sendClue: props.sendClue,
      error: false
    }

    this.sendClue = this.sendClue.bind(this)
  }

  static get propTypes () {
    return {
      sendClue: PropTypes.func,
      buttonColor: PropTypes.string,
      clueError: PropTypes.bool
    }
  }

  sendClue () {
    this.setState({ error: false })
    if (this.validClue(this.state.word, this.state.number)) {
      this.state.sendClue(this.state.word, this.state.number)
      this.setState({ word: '', number: 1 })
    } else {
      this.setState({ error: true })
    }
  }

  validClue (clue, number) {
    return clue.length > 0 && clue.length <= 32 && clue.match(/^[^ \t\n\r\f\v_.]+$/) &&
      number && (number === '-1' || (number > 0 && number < 10))
  }

  render () {
    return (
      <Grid container item justify="center" xs={12} className="clue-sender">
        <Grid item xs={12}>
          {
            this.props.clueError &&
            <span style={{ color: 'red' }}>
              { "Une erreur est survenue lors de l'enregistrement de l'indice, vérifiez qu'il soit valide et réessayez" }
            </span>
          }
        </Grid>
        <Grid item xs={12}>
          {
            this.state.error &&
            <span style={{ color: 'red' }}>
              { "Votre indice est invalide. Il doit avoir entre 1 et 32 caracteres et ne faire qu'un seul mot ('-' acceptés pour les mots composés). Vérifiez également que vous avez bien sélectionné un nombre." }
            </span>
          }
        </Grid>
        <Grid item xs={7}>
          <Input fullWidth onChange={(event) => this.setState({ word: event.target.value })}/>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup style={{ display: 'inline' }} aria-label="number" name="number1"
                      onChange={(event) => this.setState({ number: event.target.value })}>
            <FormControlLabel value="1" control={<Radio color={this.props.buttonColor}/>} label="1" labelPlacement="bottom"/>
            <FormControlLabel value="2" control={<Radio color={this.props.buttonColor}/>} label="2" labelPlacement="bottom"/>
            <FormControlLabel value="3" control={<Radio color={this.props.buttonColor}/>} label="3" labelPlacement="bottom"/>
            <FormControlLabel value="4" control={<Radio color={this.props.buttonColor}/>} label="4" labelPlacement="bottom"/>
            <FormControlLabel value="5" control={<Radio color={this.props.buttonColor}/>} label="5" labelPlacement="bottom"/>
            <FormControlLabel value="6" control={<Radio color={this.props.buttonColor}/>} label="6" labelPlacement="bottom"/>
            <FormControlLabel value="7" control={<Radio color={this.props.buttonColor}/>} label="7" labelPlacement="bottom"/>
            <FormControlLabel value="8" control={<Radio color={this.props.buttonColor}/>} label="8" labelPlacement="bottom"/>
            <FormControlLabel value="9" control={<Radio color={this.props.buttonColor}/>} label="9" labelPlacement="bottom"/>
            <FormControlLabel value="-1" control={<Radio color={this.props.buttonColor}/>} label="inf" labelPlacement="bottom"/>
          </RadioGroup>
        </Grid>
        <Grid item xs={7}>
          <Button onClick={this.sendClue} variant="contained" color={this.props.buttonColor} disabled={!this.validClue(this.state.word, this.state.number)}>
            { "Envoyer l'indice" }
          </Button>
        </Grid>
      </Grid>
    )
  }
}

export default ClueSender
