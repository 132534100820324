import './global.scss'
import App from './components/App'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route } from 'react-router-dom'
import Index from './components/index/Index'
import Game from './components/game/Game'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App>
        <Route exact path="/" component={Index} />
        <Route path="/game" component={Game} />
      </App>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)
