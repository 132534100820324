import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#8ca9e6',
      main: '#5b7ab4',
      dark: '#284e84',
      contrastText: '#fff'
    },
    secondary: {
      light: '#ffa8a4',
      main: '#ff7675',
      dark: '#c74549',
      contrastText: '#fff'
    }
  }
})
