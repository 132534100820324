import React from 'react'
import './Board.scss'
import Grid from '@material-ui/core/Grid'
import Card from './Card'
import PropTypes from 'prop-types'
import CardSpacer from './CardSpacer'

class Board extends React.Component {
  constructor (props) {
    super(props)

    this.drawCard = this.drawCard.bind(this)

    this.state = {
      gameId: props.gameId,
      sendReveal: props.sendReveal
    }
  }

  static get propTypes () {
    return {
      sendReveal: PropTypes.func,
      gameId: PropTypes.string,
      cards: PropTypes.array,
      isSpy: PropTypes.bool,
      colorBlind: PropTypes.bool,
      alwaysDisplayWords: PropTypes.bool,
      playersTurn: PropTypes.bool,
      showColor: PropTypes.bool
    }
  }

  drawCard (card, i) {
    if (card) {
      return (
        <Card key={i}
              isSpy={this.props.isSpy}
              showColor={this.props.showColor}
              color={card.color}
              word={card.word}
              playersTurn={this.props.playersTurn}
              revealed={card.revealed}
              sendReveal={this.state.sendReveal}
              colorBlind={this.props.colorBlind}
              alwaysDisplayWords={this.props.alwaysDisplayWords}
        />
      )
    } else {
      return (
        <CardSpacer key={i} />
      )
    }
  }

  render () {
    let cards = []
    for (let i = 0; i < this.props.cards.length; ++i) {
      if (i % 5 === 0) {
        cards.push(null)
        cards.push(null)
      }
      cards.push(this.props.cards[i])
    }
    cards.push(null)
    cards = cards.slice(1, cards.length)

    return (
      <Grid container item justify="center" xs={12} spacing={1} className="board">
        {
          cards.map((card, i) =>
            this.drawCard(card, i)
          )
        }
      </Grid>
    )
  }
}

export default Board
