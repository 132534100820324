import React from 'react'
import './App.scss'
import { ThemeProvider } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { theme } from '../utils/theme'

function App (props) {
  return (
    <ThemeProvider theme={theme}>
      <Grid container className="main-grid content">
        <Grid container item xs={12} direction="column" justify="space-between">
          <Grid item style={{ marginBottom: '30px' }}>
            { props.children }
          </Grid>
          <Grid item className="credits">
            <span className="title">
              Code Names v1.2 (05/2021)
            </span>
            { ' - ' }
            <span className="implem">
              Implémentation : Maxence Blomme
            </span>
            { ' - ' }
            <span className="source">
              <a rel="noreferrer" target="_blank" href="https://gitlab.com/maxence.blomme/code-names">Code Source</a>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

App.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export default App
