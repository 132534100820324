import React, { Component } from 'react'
import './GameStatus.scss'
import Grid from '@material-ui/core/Grid'
import { GameStateEnum } from '../../../utils/enums'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import ClueSender from './ClueSender'
import Box from '@material-ui/core/Box'

class GameStatus extends Component {
  constructor (props) {
    super(props)

    this.status = this.status.bind(this)
    this.drawAction = this.drawAction.bind(this)

    this.state = {}
  }

  static get propTypes () {
    return {
      endTurn: PropTypes.func,
      sendClue: PropTypes.func,
      game: PropTypes.any,
      buttonColor: PropTypes.string,
      colorBlind: PropTypes.bool,
      playersTurn: PropTypes.bool,
      isSpy: PropTypes.bool,
      clueError: PropTypes.bool,
      mobile: PropTypes.bool,
      xs: PropTypes.number,
      sm: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number
    }
  }

  status () {
    switch (this.props.game.state) {
      case GameStateEnum.BLUE_SPY_TURN:
        return <span>ATTENTE ESPION BLEU</span>
      case GameStateEnum.BLUE_TURN:
        return <span>ATTENTE JOUEURS BLEUS</span>
      case GameStateEnum.RED_SPY_TURN:
        return <span>ATTENTE ESPION ROUGE</span>
      case GameStateEnum.RED_TURN:
        return <span>ATTENTE JOUEURS ROUGES</span>
      case GameStateEnum.FINISHED:
        return <span className={this.props.game.blue_won ? 'blue text' : 'red text'}>
                    VICTOIRE DES {this.props.game.blue_won ? 'BLEUS' : 'ROUGES'}
                </span>
      default:
        return <span>ERREUR</span>
    }
  }

  drawAction () {
    if (!this.props.playersTurn) {
      return null
    } else if (this.props.isSpy) {
      return (
        <ClueSender sendClue={this.props.sendClue}
                    playersTurn={this.props.playersTurn}
                    clueError={this.props.clueError}
                    buttonColor={this.props.buttonColor} />
      )
    } else {
      return (
        <Grid item xs={12}>
          <Button variant="contained" color={this.props.buttonColor} onClick={this.props.endTurn}>Terminer le tour</Button>
        </Grid>
      )
    }
  }

  render () {
    if (this.props.mobile) {
      return (
        <Grid container item justify="center" className="game-status mobile-status">
          <Grid item xs={1}>
            <span className={'blue text' + (this.props.colorBlind ? ' cb' : '')}>{this.props.game.remaining_blue}</span>
          </Grid>
          <Grid item xs={10}>
            { this.status() }
          </Grid>
          <Grid item xs={1}>
            <span className={'red text' + (this.props.colorBlind ? ' cb' : '')}>{this.props.game.remaining_red}</span>
          </Grid>
        </Grid>
      )
    }

    const display = {
      xs: this.props.xs ? 'block' : 'none',
      sm: this.props.sm ? 'block' : 'none',
      md: this.props.md ? 'block' : 'none',
      lg: this.props.lg ? 'block' : 'none'
    }

    return (
      <Grid component={Box}
            container
            item
            justify="center"
            xs={this.props.xs}
            sm={this.props.sm}
            md={this.props.md}
            lg={this.props.lg}
            className="game-status"
            display={display}>
        <Grid container item justify="center" xs={12} className="content-card">
          <Grid item xs={1}>
            <span className={'blue text' + (this.props.colorBlind ? ' cb' : '')}>{this.props.game.remaining_blue}</span>
          </Grid>
          <Grid item xs={10}>
            { this.status() }
          </Grid>
          <Grid item xs={1}>
            <span className={'red text' + (this.props.colorBlind ? ' cb' : '')}>{this.props.game.remaining_red}</span>
          </Grid>
          {
            this.drawAction()
          }
        </Grid>
      </Grid>
    )
  }
}

export default GameStatus
