import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import SettingsIcon from '@material-ui/icons/Settings'
import PropTypes from 'prop-types'
import './GameSettings.scss'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

class GameSettings extends Component {
  constructor (props) {
    super(props)

    this.drawColorBlind = this.drawColorBlind.bind(this)
    this.drawAlwaysDisplayWords = this.drawAlwaysDisplayWords.bind(this)

    this.state = { opened: false }
  }

  static get propTypes () {
    return {
      editColorBlind: PropTypes.func,
      colorBlind: PropTypes.bool,
      editAlwaysDisplayWords: PropTypes.func,
      alwaysDisplayWords: PropTypes.bool,
      buttonColor: PropTypes.string,
      card: PropTypes.bool
    }
  }

  drawColorBlind () {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={this.props.colorBlind}
            onChange={event => this.props.editColorBlind(event.target.checked)}
            name="colorBlind"
            color={this.props.buttonColor}
          />
        }
        label="Mode daltonien"
      />
    )
  }

  drawAlwaysDisplayWords () {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={this.props.alwaysDisplayWords}
            onChange={event => this.props.editAlwaysDisplayWords(event.target.checked)}
            name="alwaysDisplayWords"
            color={this.props.buttonColor}
          />
        }
        label="Toujours afficher les mots"
      />
    )
  }

  render () {
    const mobileDisplay = {
      xs: 'block',
      md: 'none'
    }
    const desktopDisplay = {
      xs: 'none',
      md: 'block'
    }

    if (this.props.card) {
      return (
        <Grid container item justify="center" xs={12}>
          <Grid component={Box}
                display={desktopDisplay}
                container
                item
                justify="center"
                xs={12}>
            <Grid container item justify="center" xs={12} className="settings">
              <Grid item xs={12}>
                <IconButton
                  aria-label="settings"
                  style={{ color: 'white' }}
                  onClick={() => this.setState({ opened: !this.state.opened })}>
                  <SettingsIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={11} md={6} lg={4} className="content-card" style={{ display: this.state.opened ? 'block' : 'none' }}>
                {
                  this.drawColorBlind()
                }
                <br />
                {
                  this.drawAlwaysDisplayWords()
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid component={Box}
                display={mobileDisplay}
                container
                item
                justify="center"
                xs={12}
                style={{ height: '15px' }}
          />
        </Grid>
      )
    }

    return (
      <Grid item xs={12}>
        {
          this.drawColorBlind()
        }
        <br />
        {
          this.drawAlwaysDisplayWords()
        }
      </Grid>
    )
  }
}

export default GameSettings
