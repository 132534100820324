import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PlayerList from '../common/PlayerList'
import ClueList from './ClueList'
import GameStatus from './GameStatus'
import PropTypes from 'prop-types'
import './BottomRow.scss'
import Box from '@material-ui/core/Box'
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import { Done, List, Publish, Search, Settings, ViewAgenda } from '@material-ui/icons'
import ClueSender from './ClueSender'
import Button from '@material-ui/core/Button'
import GameSettings from '../common/GameSettings'

class BottomRow extends Component {
  constructor (props) {
    super(props)

    this.switchNav = this.switchNav.bind(this)
    this.drawNavItem = this.drawNavItem.bind(this)
    this.canSendClue = this.canSendClue.bind(this)
    this.canEndTurn = this.canEndTurn.bind(this)

    this.state = {
      sendClue: props.sendClue,
      nav: ''
    }
  }

  static get propTypes () {
    return {
      sendClue: PropTypes.func,
      sendReset: PropTypes.func,
      endTurn: PropTypes.func,
      toggleSpy: PropTypes.func,
      editColorBlind: PropTypes.func,
      editAlwaysDisplayWords: PropTypes.func,
      players: PropTypes.array,
      clues: PropTypes.array,
      game: PropTypes.any,
      playerName: PropTypes.string,
      buttonColor: PropTypes.string,
      spectator: PropTypes.bool,
      colorBlind: PropTypes.bool,
      alwaysDisplayWords: PropTypes.bool,
      registered: PropTypes.bool,
      resetting: PropTypes.bool,
      isSpy: PropTypes.bool,
      playersTurn: PropTypes.bool,
      clueError: PropTypes.bool
    }
  }

  switchNav (event, newValue) {
    this.setState({ nav: newValue })
  }

  canEndTurn () {
    return !this.props.isSpy && this.props.playersTurn
  }

  canSendClue () {
    return this.props.isSpy && this.props.playersTurn
  }

  drawNavItem () {
    switch (this.state.nav) {
      case 'players':
        return (
          <PlayerList xs={12} sm={11} md={false} lg={false}
                      players={this.props.players}
                      playerName={this.props.playerName}
                      colorBlind={this.props.colorBlind} />
        )
      case 'form':
        if (this.canSendClue()) {
          return (
            <Grid container item justify="center" xs={12} className="content-card">
              <ClueSender sendClue={this.props.sendClue}
                          playersTurn={this.props.playersTurn}
                          clueError={this.props.clueError}
                          buttonColor={this.props.buttonColor} />
            </Grid>
          )
        }
        break
      case 'clues':
        return (
          <ClueList xs={12} sm={11} md={6} lg={3}
                    clues={this.props.clues}
                    colorBlind={this.props.colorBlind}/>
        )
      case 'end':
        if (this.canEndTurn()) {
          return (
            <Grid container item justify="center" xs={12} className="content-card">
              <Button variant="contained" color={this.props.buttonColor} onClick={this.props.endTurn}>Terminer le tour</Button>
            </Grid>
          )
        }
        break
      case 'settings':
        return (
          <Grid container item justify="center" xs={12} className="content-card">
            <GameSettings buttonColor={this.props.buttonColor}
                          colorBlind={this.props.colorBlind}
                          editColorBlind={this.props.editColorBlind}
                          alwaysDisplayWords={this.props.alwaysDisplayWords}
                          editAlwaysDisplayWords={this.props.editAlwaysDisplayWords}
            />
            <Grid item xs={12}>
              <Button variant="contained" color={this.props.buttonColor} className={'game-button ' + (this.props.spectator ? 'neutral-button' : '')} onClick={this.props.toggleSpy}>
                {
                  !this.props.spectator
                    ? 'Passer ' + (this.props.isSpy ? 'joueur' : 'espion')
                    : (this.props.isSpy ? 'Cacher' : 'Révéler') + ' la carte'
                }
              </Button>
            </Grid>
            {
              !this.props.spectator &&
              <Grid item xs={12}>
                <Button variant="contained" color={this.props.buttonColor} className="game-button" onClick={this.props.sendReset}>Recommencer une partie</Button>
              </Grid>
            }
          </Grid>
        )
      default:
        return <span />
    }
  }

  render () {
    const desktopDisplay = {
      xs: 'none',
      md: 'block'
    }
    const mobileDisplay = {
      xs: 'block',
      md: 'none'
    }
    const navactionClasses = {
      root: 'navigation ' + this.props.buttonColor + (this.props.colorBlind ? ' cb' : '')
    }

    return (
      <Grid container item justify="center" xs={12} className="bottom-row">
        <Grid component={Box}
              display={desktopDisplay}
              container
              item
              justify="center"
              xs={12}
              className="desktop">
          <Grid container item justify="center" xs={12} spacing={3}>
            <PlayerList xs={false} sm={false} md={false} lg={3}
                        players={this.props.players}
                        playerName={this.props.playerName}
                        colorBlind={this.props.colorBlind} />
            <GameStatus xs={12} sm={11} md={12} lg={6}
                        mobile={false}
                        game={this.props.game}
                        playersTurn={this.props.playersTurn}
                        isSpy={this.props.isSpy}
                        endTurn={this.props.endTurn}
                        buttonColor={this.props.buttonColor}
                        sendClue={this.state.sendClue}
                        clueError={this.props.clueError}
                        colorBlind={this.props.colorBlind} />
            <ClueList xs={12} sm={11} md={6} lg={3}
                      clues={this.props.clues}
                      colorBlind={this.props.colorBlind}/>
            <PlayerList xs={12} sm={11} md={6} lg={false}
                        players={this.props.players}
                        playerName={this.props.playerName}
                        colorBlind={this.props.colorBlind} />
          </Grid>
        </Grid>
        <Grid component={Box}
              display={mobileDisplay}
              container
              item
              justify="center"
              xs={12}
              className="mobile">
          <Grid container item justify="center" xs={12}>
            <Grid container item direction="column" xs={12}>
              <Grid container item justify="center">
                {
                  this.drawNavItem()
                }
              </Grid>
              <Grid item>
                <BottomNavigation value={this.state.nav}
                                  onChange={this.switchNav}
                                  showLabels
                                  className="nav">
                  <BottomNavigationAction classes={navactionClasses} value="" label="Base" icon={<ViewAgenda />} />
                  <BottomNavigationAction classes={navactionClasses} value="players" label="Joueurs" icon={<List />} />
                  <BottomNavigationAction classes={navactionClasses} value="clues" label="Indices" icon={<Search />} />
                  {
                    this.canSendClue() &&
                    <BottomNavigationAction classes={navactionClasses} value="form" label="Envoi d'indice" icon={<Publish />} />
                  }
                  {
                    this.canEndTurn() &&
                    <BottomNavigationAction classes={navactionClasses} value="end" label="Fin de tour" icon={<Done />} />
                  }
                  <BottomNavigationAction classes={navactionClasses} value="settings" label="Paramètres" icon={<Settings />} />
                </BottomNavigation>
              </Grid>
              <GameStatus mobile game={this.props.game} colorBlind={this.props.colorBlind} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default BottomRow
