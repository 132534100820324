import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

class CardSpacer extends React.Component {
  render () {
    return (
      <Grid component={Box} item xs={1} display={{ xs: 'none', lg: 'block' }} />
    )
  }
}

export default CardSpacer
