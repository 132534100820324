export const RequestTypeEnum = {
  REVEAL: 1,
  RESET: 2,
  REGISTER_PLAYER: 3,
  GET_PLAYERS: 4,
  REMOVE_PLAYER: 5,
  GET_CARDS: 6,
  TOGGLE_SPY: 7,
  GET_CLUES: 8,
  ADD_CLUE: 9,
  END_TURN: 10
}

export const GameStateEnum = {
  BLUE_SPY_TURN: 1,
  RED_SPY_TURN: 2,
  BLUE_TURN: 3,
  RED_TURN: 4,
  FINISHED: 5
}
